import React, { useCallback, useEffect, useRef, useState } from "react";
import LightGallery from "lightgallery/react";
import 'bootstrap/dist/css/bootstrap.css';
import { saveAs } from 'file-saver'

import {  Container, Row, Col } from 'reactstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import * as Yup from "yup";
import $ from 'jquery'



import { Formik } from 'formik'

import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lg-video.css";
import "lightgallery/react/Lightgallery.es5";

import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import lgVideo from "lightgallery/plugins/video";

import photosIcon from '../assets/images/images-icon.png'
import vidoesIcon from '../assets/images/videos-icon.png'
import snbLogo from '../assets/images/snb-logo.png'

import complete3 from '../assets/images/shapes/complete-3.png'

import img1 from '../assets/images/img-gallery/img1.jpg'
import img2 from '../assets/images/img-gallery/img2.jpg'
import img3 from '../assets/images/img-gallery/img3.jpg'
import img4 from '../assets/images/img-gallery/img4.jpg'
import img5 from '../assets/images/img-gallery/img5.jpg'
import img6 from '../assets/images/img-gallery/img6.jpg'
import img7 from '../assets/images/img-gallery/img7.jpg'
import img8 from '../assets/images/img-gallery/img8.jpg'
import img9 from '../assets/images/img-gallery/img9.jpg'
import img10 from '../assets/images/img-gallery/img10.jpg'
import img11 from '../assets/images/img-gallery/img11.jpg'
import img12 from '../assets/images/img-gallery/img12.jpg'
import img13 from '../assets/images/img-gallery/img13.jpg'
import img14 from '../assets/images/img-gallery/img14.jpg'
import img15 from '../assets/images/img-gallery/img15.jpg'
import img16 from '../assets/images/img-gallery/img16.jpg'
import img17 from '../assets/images/img-gallery/img17.jpg'
import img18 from '../assets/images/img-gallery/img18.jpg'
import img19 from '../assets/images/img-gallery/img19.jpg'
import img20 from '../assets/images/img-gallery/img20.jpg'
import img21 from '../assets/images/img-gallery/img21.jpg'
import img22 from '../assets/images/img-gallery/img22.jpg'
import img23 from '../assets/images/img-gallery/img23.jpg'
import img24 from '../assets/images/img-gallery/img24.jpg'
import img25 from '../assets/images/img-gallery/img25.jpg'
import img26 from '../assets/images/img-gallery/img26.jpg'
import img27 from '../assets/images/img-gallery/img27.jpg'
import img28 from '../assets/images/img-gallery/img28.jpg'
import img29 from '../assets/images/img-gallery/img29.jpg'
import img30 from '../assets/images/img-gallery/img30.jpg'
import img31 from '../assets/images/img-gallery/img31.jpg'
import img32 from '../assets/images/img-gallery/img32.jpg'
import img33 from '../assets/images/img-gallery/img33.jpg'
import img34 from '../assets/images/img-gallery/img34.jpg'
import img35 from '../assets/images/img-gallery/img35.jpg'
import img36 from '../assets/images/img-gallery/img36.jpg'
import img37 from '../assets/images/img-gallery/img37.jpg'
import img38 from '../assets/images/img-gallery/img38.jpg'
import img39 from '../assets/images/img-gallery/img39.jpg'
import img40 from '../assets/images/img-gallery/img40.jpg'
import img41 from '../assets/images/img-gallery/img41.jpg'
import img42 from '../assets/images/img-gallery/img42.jpg'
import img43 from '../assets/images/img-gallery/img43.jpg'
import img44 from '../assets/images/img-gallery/img44.jpg'
import img45 from '../assets/images/img-gallery/img45.jpg'
import img46 from '../assets/images/img-gallery/img46.jpg'
import img47 from '../assets/images/img-gallery/img47.jpg'
import img48 from '../assets/images/img-gallery/img48.jpg'
import img49 from '../assets/images/img-gallery/img49.jpg'
import img50 from '../assets/images/img-gallery/img50.jpg'
import img51 from '../assets/images/img-gallery/img51.jpg'
import img52 from '../assets/images/img-gallery/img52.jpg'
import img53 from '../assets/images/img-gallery/img53.jpg'
import img54 from '../assets/images/img-gallery/img54.jpg'
import img55 from '../assets/images/img-gallery/img55.jpg'
import img56 from '../assets/images/img-gallery/img56.jpg'
import img57 from '../assets/images/img-gallery/img57.jpg'
import img58 from '../assets/images/img-gallery/img58.jpg'
import img59 from '../assets/images/img-gallery/img59.jpg'
import img60 from '../assets/images/img-gallery/img60.jpg'
import img61 from '../assets/images/img-gallery/img61.jpg'
import img62 from '../assets/images/img-gallery/img62.jpg'
import img63 from '../assets/images/img-gallery/img63.jpg'
import img64 from '../assets/images/img-gallery/img64.jpg'
import img65 from '../assets/images/img-gallery/img65.jpg'
import img66 from '../assets/images/img-gallery/img66.jpg'
import img67 from '../assets/images/img-gallery/img67.jpg'
import img68 from '../assets/images/img-gallery/img68.jpg'
import img69 from '../assets/images/img-gallery/img69.jpg'
import img70 from '../assets/images/img-gallery/img70.jpg'
import img71 from '../assets/images/img-gallery/img71.jpg'
import img72 from '../assets/images/img-gallery/img72.jpg'
import img73 from '../assets/images/img-gallery/img73.jpg'
import img74 from '../assets/images/img-gallery/img74.jpg'
import img75 from '../assets/images/img-gallery/img75.jpg'
import img76 from '../assets/images/img-gallery/img76.jpg'
import img77 from '../assets/images/img-gallery/img77.jpg'
import img78 from '../assets/images/img-gallery/img78.jpg'
import img79 from '../assets/images/img-gallery/img79.jpg'
import img80 from '../assets/images/img-gallery/img80.jpg'
import img81 from '../assets/images/img-gallery/img81.jpg'
import img82 from '../assets/images/img-gallery/img82.jpg'
import img83 from '../assets/images/img-gallery/img83.jpg'
import img84 from '../assets/images/img-gallery/img84.jpg'
import img85 from '../assets/images/img-gallery/img85.jpg'
import img86 from '../assets/images/img-gallery/img86.jpg'
import img87 from '../assets/images/img-gallery/img87.jpg'
import img88 from '../assets/images/img-gallery/img88.jpg'




import video from '../assets/videos/video.mp4'
import posterFinal from '../assets/videos/poster-final.png'

const imagesData = [
{'id': 1,'name': 'Poster 1', 'file': img1},
{'id': 2,'name': 'Poster 2', 'file': img2},
{'id': 3,'name': 'Poster 3', 'file': img3},
{'id': 4,'name': 'Poster 4', 'file': img4},
{'id': 5,'name': 'Poster 5', 'file': img5},
{'id': 6,'name': 'Poster 6', 'file': img6},
{'id': 7,'name': 'Poster 7', 'file': img7},
{'id': 8,'name': 'Poster 8', 'file': img8},
{'id': 9,'name': 'Poster 9', 'file': img9},
{'id': 10,'name': 'Poster 10', 'file': img10},
{'id': 11,'name': 'Poster 11', 'file': img11},
{'id': 12,'name': 'Poster 12', 'file': img12},
{'id': 13,'name': 'Poster 13', 'file': img13},
{'id': 14,'name': 'Poster 14', 'file': img14},
{'id': 15,'name': 'Poster 15', 'file': img15},
{'id': 16,'name': 'Poster 16', 'file': img16},
{'id': 17,'name': 'Poster 17', 'file': img17},
{'id': 18,'name': 'Poster 18', 'file': img18},
{'id': 19,'name': 'Poster 19', 'file': img19},
{'id': 20,'name': 'Poster 20', 'file': img20},
{'id': 21,'name': 'Poster 21', 'file': img21},
{'id': 22,'name': 'Poster 22', 'file': img22},
{'id': 23,'name': 'Poster 23', 'file': img23},
{'id': 24,'name': 'Poster 24', 'file': img24},
{'id': 25,'name': 'Poster 25', 'file': img25},
{'id': 26,'name': 'Poster 26', 'file': img26},
{'id': 27,'name': 'Poster 27', 'file': img27},
{'id': 28,'name': 'Poster 28', 'file': img28},
{'id': 29,'name': 'Poster 29', 'file': img29},
{'id': 30,'name': 'Poster 30', 'file': img30},
{'id': 31,'name': 'Poster 31', 'file': img31},
{'id': 32,'name': 'Poster 32', 'file': img32},
{'id': 33,'name': 'Poster 33', 'file': img33},
{'id': 34,'name': 'Poster 34', 'file': img34},
{'id': 35,'name': 'Poster 35', 'file': img35},
{'id': 36,'name': 'Poster 36', 'file': img36},
{'id': 37,'name': 'Poster 37', 'file': img37},
{'id': 38,'name': 'Poster 38', 'file': img38},
{'id': 39,'name': 'Poster 39', 'file': img39},
{'id': 40,'name': 'Poster 40', 'file': img40},
{'id': 41,'name': 'Poster 41', 'file': img41},
{'id': 42,'name': 'Poster 42', 'file': img42},
{'id': 43,'name': 'Poster 43', 'file': img43},
{'id': 44,'name': 'Poster 44', 'file': img44},
{'id': 45,'name': 'Poster 45', 'file': img45},
{'id': 46,'name': 'Poster 46', 'file': img46},
{'id': 47,'name': 'Poster 47', 'file': img47},
{'id': 48,'name': 'Poster 48', 'file': img48},
{'id': 49,'name': 'Poster 49', 'file': img49},
{'id': 50,'name': 'Poster 50', 'file': img50},
{'id': 51,'name': 'Poster 51', 'file': img51},
{'id': 52,'name': 'Poster 52', 'file': img52},
{'id': 53,'name': 'Poster 53', 'file': img53},
{'id': 54,'name': 'Poster 54', 'file': img54},
{'id': 55,'name': 'Poster 55', 'file': img55},
{'id': 56,'name': 'Poster 56', 'file': img56},
{'id': 57,'name': 'Poster 57', 'file': img57},
{'id': 58,'name': 'Poster 58', 'file': img58},
{'id': 59,'name': 'Poster 59', 'file': img59},
{'id': 60,'name': 'Poster 60', 'file': img60},
{'id': 61,'name': 'Poster 61', 'file': img61},
{'id': 62,'name': 'Poster 62', 'file': img62},
{'id': 63,'name': 'Poster 63', 'file': img63},
{'id': 64,'name': 'Poster 64', 'file': img64},
{'id': 65,'name': 'Poster 65', 'file': img65},
{'id': 66,'name': 'Poster 66', 'file': img66},
{'id': 67,'name': 'Poster 67', 'file': img67},
{'id': 68,'name': 'Poster 68', 'file': img68},
{'id': 69,'name': 'Poster 69', 'file': img69},
{'id': 70,'name': 'Poster 70', 'file': img70},
{'id': 71,'name': 'Poster 71', 'file': img71},
{'id': 72,'name': 'Poster 72', 'file': img72},
{'id': 73,'name': 'Poster 73', 'file': img73},
{'id': 74,'name': 'Poster 74', 'file': img74},
{'id': 75,'name': 'Poster 75', 'file': img75},
{'id': 76,'name': 'Poster 76', 'file': img76},
{'id': 77,'name': 'Poster 77', 'file': img77},
{'id': 78,'name': 'Poster 78', 'file': img78},
{'id': 79,'name': 'Poster 79', 'file': img79},
{'id': 80,'name': 'Poster 80', 'file': img80},
{'id': 81,'name': 'Poster 81', 'file': img81},
{'id': 82,'name': 'Poster 82', 'file': img82},
{'id': 83,'name': 'Poster 83', 'file': img83},
{'id': 84,'name': 'Poster 84', 'file': img84},
{'id': 85,'name': 'Poster 85', 'file': img85},
{'id': 86,'name': 'Poster 86', 'file': img86},
{'id': 87,'name': 'Poster 87', 'file': img87},
{'id': 88,'name': 'Poster 88', 'file': img88}
    
]

const videosData = [
    {
        "id": 1,
        "name": "Video 1",
        "file": video,
        "poster": posterFinal
    },
]


const Home = () =>{

        document.title = "SNB - Strategy Offsite Meeting"

        const lightGallery = useRef(null)

        const [show, setShow] = useState(false);
        const [images, setImages] = useState([])
        const [videos, setVideos] = useState([])
        const [downloadUrl, setDownloadUrl] = useState()


        const handleClose = () => setShow(false);
        const handleShow = () => setShow(true);



        const fetchImages = () => {
  
                    // fetch("http://localhost:8000/api/images")
                    // .then(res => res.json())
                    // .then((res) => {                          
                    //     setImages(res)                                                      
                    // })
                    // .catch((res)=> console.log(res))


                setImages(imagesData)     
                setTimeout(function(){                
                            if (document.getElementsByClassName("gallery-video")){
                        document.getElementsByClassName("gallery-image")[0].children[0].click() 
                            
                        }
                        }, 100)
                
            }
              
        const fetchVideos = () => {
                    // fetch("http://localhost:8000/api/videos")
                    // .then(res => res.json())
                    // .then((res) => {  
                    //     console.log(res);
                    //     setVideos(res)                                             
                    // })
                    // .catch((res)=> console.log(res))
                    setVideos(videosData)  
                    setTimeout(function(){                
                        if (document.getElementsByClassName("gallery-video")){
                        
                        document.getElementsByClassName("gallery-video")[0].children[0].click()
                        
                    }
                    }, 100)
                }

        const sendVisitorData = (values) => {
                let formData = new FormData();
                    formData.append('name', values['name']);
                    formData.append('email', values['email']);
                    
                    fetch('https://snb.api.simcoetraining.com/api/add-user-data', {
                        method: 'POST',
                        body: formData
                    }).then(res=>res.json()).then(function (res) {
                        if (res['status']){
                            console.log();
                            document.querySelector('.btn-close').click()
                            fetchVideos();
                            window.sessionStorage.setItem("userData", true);
                        }
                        else{
                            window.alert(res['message']);
                            return false;
                        }
                    }).catch(function (error) {
                        window.alert(error);
                    });
                    

                }
              
        
        const onInit = useCallback((detail) => {
            if (detail) {
                lightGallery.current = detail.instance;
            }
        }, []);

        // useEffect(()=>{
        //     setTimeout(function(){                
        //         if (document.getElementsByClassName("gallery-image")){
        //         document.getElementsByClassName("gallery-image")[0].children[0].click()                }
        //     }, 100)
        // }, [images])
        

        // useEffect(()=>{
        //     setTimeout(function(){                
        //         if (document.getElementsByClassName("gallery-video")){
                
        //     }
        //     }, 100)
        // }, [videos])


        const onHasVideo = () => {
            var downloadBtn = document.querySelectorAll('.lg-download')[0];
            
            downloadBtn.onclick = function (event){

                if (!window.sessionStorage.getItem("userData")){
                    event.preventDefault();
                    document.querySelector('#modal-btn').click()
                }
                
            }
        }
        
        const getImages = useCallback(() => {
            return images.map((image) => {
                    return(

                        // <a key={image.id} download={image.name} data-download-url={`data:image/jpg;base64,${image.base64_image}`}  href={image.file} className="gallery-image" >
                        <a key={image.id} download={image.name} data-download-url={image.file}  href={image.file} className="gallery-image" >
                    
                            <img
                                id="images-gallery"
                                    style={{'display':'none'}}
                                    className="galleryImage"                    
                                    src={image.file}
                                />
                        </a>                          
                      )
            })
        }, [images])

        const getVideos = useCallback(() => {
            return videos.map((video) => {               
                return(
    
                    <a            
                        key={video.id}
                        className="aspect-[3/4] col-span-1 gallery-video relative w-full cursor-pointer"
                        data-video={`{"source": [{"src": "${video.file}", "type":"video/mp4"}], "attributes": {"preload": true, "controls": true}}`}
                        data-poster={video.poster}
                        // data-download-url={`data:video/mp4;base64,${video.base64_video}`}
                        data-download-url={video.file}
                        id='videos-link'
                        >
                
                        <img src={video.poster} style={{'display':'none'}}/>
                
                        </a>
                                       
                    )
                }, [videos])
            })

        useEffect(() => {
            
                lightGallery.current.refresh()
                                    
            }, [images, videos])
         

            const clearGallery = () => {

       
            if (lightGallery.current['items'].length > 0){                
            setImages([])    
            setVideos([])    
            }
            
            }

            const schema = Yup.object().shape({
            email: Yup.string().email().required("Please provide email"),
            name: Yup.string().required("Please provide name")
            })
        

    return(

        <div className="homepage">


            <div className="content">
                
                <h3>Strategy Offsite Meeting <br/>Athens 11-12 NOV 2022</h3>
            </div>
        
        
        <Container className="icons">
                

            <Row>
                <Col xs={12} sm={1}></Col>
                <Col onClick={() => {clearGallery(); fetchImages();}} xs={12} sm={5} className='text-center' style={{"zIndex": "1000"}} >
                        <img                        
                        
                        src={photosIcon} 
                        alt="images-icon" />
                        <p>Group Photo</p>
                </Col>
             
                <Col onClick={() => {clearGallery(); fetchVideos();}} xs={12} sm={5} className='text-center' style={{"zIndex": "1000"}} >
                    <img 
                    
                    src={vidoesIcon} 
                    alt="videos-icon" />
                    <p>Video</p>
                </Col>
                <Col xs={12} sm={1}></Col>
            </Row>

            <div>
                <img id="filled-white" src={complete3} alt="snb logo"/>
                <img id="snbLogo" src={snbLogo} alt="snb logo" />
            </div>

            </Container>


            <div className="galleryContainer">          

                <LightGallery
                    onInit={onInit}
                    plugins={[lgThumbnail, lgZoom, lgVideo]}
                    onHasVideo={onHasVideo}              
                    preload={1}                    
                    download={true}
                    mobileSettings={
                        {download:true}
                    }
                    
                    >                        
                    {
                        images.length > 0 ? getImages() : ""
                    }

                    {
                        videos.length > 0 ? getVideos() : ""
                    }
                    
                </LightGallery>

            </div>



        <Button id='modal-btn' variant="primary" style={{'display':'none'}} onClick={handleShow}>Launch demo modal</Button>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>
                User Data
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>

            In order to download video, provide following
            <br/>
            <br/>

            <Formik
                validationSchema={schema}
                onSubmit={(values) => {
                     sendVisitorData(values)
                }}
                initialValues={{
                    email: '',
                    name: '',
                }}
                >
 
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isValid,
                    errors,
                }) => (
        
                <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address*</Form.Label>
                    <Form.Control
                        type="text"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        isInvalid={!!errors.email}
                        isValid={touched.email && !errors.email}

                        />
                
                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>Name*</Form.Label>
                    <Form.Control
                        type="text"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        isInvalid={!!errors.name}
                        isValid={touched.name && !errors.name}/>
                
                
                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                </Form.Group>

                <Button variant="primary" type="submit">
                    Submit
                </Button>
                </Form>
      )}
    </Formik>

        </Modal.Body>
        
      </Modal>
    
    </div>
    )

}

export default Home;

